<template>
  <section v-if="data" class="o-faq-list">
    <div class="container">
      <div class="o-faq-list__container">
        <div class="o-faq-list__left-column">
          <div class="o-faq-list__header">
            <h2 class="o-faq-list__title">{{ data.title }}</h2>
            <h3 v-if="data.subtitle" class="o-faq-list__subtitle">{{ data.subtitle }}</h3>
          </div>
          <template v-if="data.button">
            <Button
              v-if="data.button.type === 'dialog'"
              modifier="tertiary"
              theme="light"
              @click="requireForm(formList[data.button.value])"
            >
              {{ data.button.title }}
            </Button>

            <Button
              v-if="data.button.type === 'node'"
              :href="data.button.value"
              modifier="primary"
              tag="a"
            >
              {{ data.button.title }}
            </Button>

            <Button
              v-if="data.button.type === 'absolute'"
              :href="data.button.value"
              modifier="primary"
              tag="a"
              target="_blank"
            >
              {{ data.button.title }}
            </Button>
          </template>
        </div>
        <div class="o-faq-list__right-column">
          <AccordionGroup
            css-class="m-accordion-questions"
            transition-name="m-accordion-questions"
            single-mode
          >
            <Accordion v-for="element in data.questions">
              <template #headerContent="{ opened }">
                {{ element.question }}
                <div class="m-accordion-questions-item__button">
                  <Button
                    tag="span"
                    css-class="button-toggle"
                    :class="{ 'button-toggle--toggled': opened }"
                  >
                    <i class="icon-main-plus"></i>
                  </Button>
                </div>
              </template>
              <div v-html="element.answer.value" class="m-accordion-questions-item__answer"></div>
            </Accordion>
          </AccordionGroup>
        </div>
      </div>
    </div>
  </section>

  <section v-else-if="!websiteConfig.modes.isProduction">
    <UtilsDataMissing :data="props.data" />
  </section>
</template>

<script setup lang="ts">
// Vue
import { ref } from 'vue';

// Components
import UtilsDataMissing from '~/components/Utils/Data/Missing/UtilsDataMissing.vue';
import Button from '@ice-products-ui/vue-library/Button';
import Accordion from '@ice-products-ui/vue-library/Accordion';
import AccordionGroup from '@ice-products-ui/vue-library/AccordionGroup';

// Composables
import useGetData from '~/composables/api/useGetData';
import useForms from '~/composables/molecules/useForms';
import useComponentSchemaValidator from '~/composables/validators/useComponentSchemaValidator';

// Types
import type { IComponentProps } from '~/typings/types/component.types';
import type { TWebsiteConfig } from '~/typings/types/website-config.types';
import type { TOFaqListTypes } from '~/components/O/Faq/List/OFaqList.types';

// Schema
import schema from '~/components/O/Faq/List/OFaqList.schema';

defineOptions({
  name: 'OPopularQuestionDefault',
});

const props = withDefaults(defineProps<IComponentProps<TOFaqListTypes>>(), {
  viewName: 'OPopularQuestionDefault',
});

const websiteConfig = useState<TWebsiteConfig>('config');

const { getComponent } = useGetData<TOFaqListTypes>();
const { requireForm, formList } = useForms();

const data = ref<TOFaqListTypes>(getComponent(props).component);

useComponentSchemaValidator(schema, props);
</script>
